/** Validate form **/
const _ = require('lodash');

// Own e-mail validation (via CheckEmail() in PHP)
$.validator.addMethod('customemail',
    _.debounce(
    function (value, element) {
            return eval($.ajax({
                url: strPath + 'components/check-email.php',
                type: 'POST',
                async: false,
                data: {txtEmail: value},
            }).responseText);
        }, 700, {'leading': true}), true
);


// Unsubscribe
function FormValidateUnsubscribe(objForm) {
    var validator = $(objForm).validate(
        {
            rules: {
                // Check if e-mail exists in database
                txtEmail: {
                    remote: {
                        url: strAjaxPath + 'validate/unsubscribe.php',
                        type: 'post',
                    },
                },
            },
            messages: {
                txtEmail: {
                    required: strLabelGiveEmail,
                    customemail: strLabelEmailInvalid,
                },
            },
            errorClass: 'd-flex invalid-feedback',
            errorElementClass: 'is-invalid',
            highlight: function (element, errorClass, validClass) {
                $(element).addClass(this.settings.errorElementClass).removeClass(errorClass);
            },
            unhighlight: function (element, errorClass, validClass) {
                $(element).removeClass(this.settings.errorElementClass).removeClass(errorClass);
            },
        });

    return validator;
}

// Login
function FormValidateLogin(objForm, strTypeLogin) {
    var validator = $(objForm).validate(
        {
            messages: {
                txtUsername: {
                    required: strLabelGiveEmail,
                    customemail: strLabelEmailInvalid,
                },
                txtPassword: {
                    required: strLabelGivePassword,
                },
            },
            errorClass: 'd-flex invalid-feedback',
            errorElementClass: 'is-invalid',
            highlight: function (element, errorClass, validClass) {
                $(element).addClass(this.settings.errorElementClass).removeClass(errorClass);
            },
            unhighlight: function (element, errorClass, validClass) {
                $(element).removeClass(this.settings.errorElementClass).removeClass(errorClass);
            },
            submitHandler: function () {
                // Check if combination username and password exists (don't check in validator, problem with errors)
                $.post(strAjaxPath + 'validate/login.php', {txtUsername: $('input#txtUsername').val(), txtPassword: $('input#txtPassword').val()}, function (strData) {
                    // Remove previous errors
                    $('input#txtUsername').removeClass('is-invalid');
                    $('input#txtPassword').removeClass('is-invalid');
                    $('input#txtPassword').parent().find('label.invalid-feedback').remove();

                    if (strData !== true) {
                        // Show error
                        $('input#txtUsername').addClass('is-invalid');
                        $('input#txtPassword').addClass('is-invalid');
                        $('input#txtPassword').after($('<label id="txtPassword" class="d-flex invalid-feedback" for="txtPassword">' + strData + '</label>'));
                    } else {
                        if (strTypeLogin == 'loginpopup') {
                            // Login (in popup) after adding giftcard code
                            $.post(strAjaxPath + 'validate/giftcard-after-login.php', '', function (strData) {
                                parent.location.reload();
                            });
                        } else {
                            // Correct => submit form
                            objForm.unbind('submit');
                            objForm.submit();
                        }
                    }
                }, 'json');
            },
        });

    return validator;
}

// Forgot password
function FormValidateForgotPassword(objForm) {
    var validator = $(objForm).validate(
        {
            rules: {
                // Check if e-mail exists and has a password
                txtEmail: {
                    remote: {
                        url: strAjaxPath + 'validate/forgot-password.php',
                        type: 'post',
                    },
                },
            },
            // Error has to be shown after the button
            errorPlacement: function (error, element) {
                $('#txtEmail-error').remove();
                if (element.attr('name') == 'txtEmail') {
                    error.insertAfter('#frmForgotPassword');
                } else {
                    error.insertAfter(element);
                }
            },
            messages: {
                txtEmail: {
                    required: strLabelGiveEmail,
                    customemail: strLabelEmailInvalid,
                },
            },
            errorClass: 'd-flex invalid-feedback',
            errorElementClass: 'is-invalid',
            highlight: function (element, errorClass, validClass) {
                $(element).addClass(this.settings.errorElementClass).removeClass(errorClass);
            },
            unhighlight: function (element, errorClass, validClass) {
                $(element).removeClass(this.settings.errorElementClass).removeClass(errorClass);
            },
        });

    return validator;
}


// Forgot password reset
function FormValidateForgotPasswordReset(objForm) {
    var validator = $(objForm).validate(
        {
            rules: {
                // Check if password is valid
                txtPassword: {
                    remote: {
                        url: strAjaxPath + 'validate/forgot-password-reset.php',
                        type: 'post',
                    },
                },
                txtPasswordConfirm: {
                    equalTo: '#txtPassword',
                },
            },
            messages: {
                txtPassword: {
                    required: strLabelGivePassword,
                },
                txtPasswordConfirm: {
                    required: strLabelConfirmPassword,
                    equalTo: strLabelPasswordsDontMatch,
                },
            },
            errorClass: 'd-flex invalid-feedback',
            errorElementClass: 'is-invalid',
            highlight: function (element, errorClass, validClass) {
                $(element).addClass(this.settings.errorElementClass).removeClass(errorClass);
            },
            unhighlight: function (element, errorClass, validClass) {
                $(element).removeClass(this.settings.errorElementClass).removeClass(errorClass);
            },
        });

    return validator;
}


// Register
function FormValidateRegister(objForm) {
    var validator = $(objForm).validate(
        {
            rules: {
                // Check if e-mail doesn't exists (with a username)
                txtEmail: {
                    remote: {
                        url: strAjaxPath + 'validate/register-email.php',
                        type: 'post',
                    },
                },
                // Check if password is valid
                // txtPassword: {
                //     remote: {
                //         url: strAjaxPath + 'validate/register-password.php',
                //         type: 'post',
                //     },
                // },
                // txtPasswordConfirm: {
                //     equalTo: '#txtPassword',
                // },
                // Check if vat number is valid
                txtVatnr: {
                    remote: {
                        url: strAjaxPath + 'validate/register-vat.php',
                        type: 'post',
                        data: {
                            txtVatpr: function () {
                                return $('#txtVatpr').val();
                            },
                        },
                    },
                },
            },
            errorClass: 'd-flex invalid-feedback',
            errorElementClass: 'is-invalid',
            highlight: function (element, errorClass, validClass) {
                $(element).addClass(this.settings.errorElementClass).removeClass(errorClass);
            },
            unhighlight: function (element, errorClass, validClass) {
                $(element).removeClass(this.settings.errorElementClass).removeClass(errorClass);
            },
            // Error zipcode has to be shown after field 'city'
            errorPlacement: function (error, element) {
                if (element.attr('name') == 'chkConditions') {
                    error.insertAfter('label[for="chkConditions"]');
                } else {
                    error.insertAfter(element);
                }
            },
            messages: {
                txtCompanyname: {
                    required: strLabelGiveCompanyname,
                },
                txtVatpr: {
                    required: strLabelGiveVatPr,
                },
                txtVatnr: {
                    required: strLabelGiveVatNr,
                },
                txtFirstname: {
                    required: strLabelGiveFirstname,
                },
                txtLastname: {
                    required: strLabelGiveLastname,
                },
                txtStreet: {
                    required: strLabelGiveStreet,
                },
                txtAddressNumber: {
                    required: strLabelGiveAddressNumber,
                },
                txtZipcode: {
                    required: strLabelGiveZipcode,
                },
                txtCity: {
                    required: strLabelGiveCity,
                },
                txtPhone: {
                    required: strLabelGivePhoneMobile,
                },
                txtEmail: {
                    required: strLabelGiveEmail,
                    customemail: strLabelEmailInvalid,
                },
                txtUserName: {
                    required: strLabelGiveUsername,
                },
                // txtPassword: {
                //     required: strLabelGivePassword,
                // },
                // txtPasswordConfirm: {
                //     required: strLabelConfirmPassword,
                //     equalTo: strLabelPasswordsDontMatch,
                // },
            },
        });

    return validator;
}

// Edit address account data
function FormValidateAddressAccount(objForm) {
    var validator = $(objForm).validate(
        {
            rules: {
                // Check if e-mail doesn't already exists for another address
                txtEmail: {
                    remote: {
                        url: strAjaxPath + 'validate/address-email.php',
                        type: 'post',
                    },
                },
                // Check if the password is valid
                txtPassword: {
                    remote: {
                        url: strAjaxPath + 'validate/register-password.php',
                        type: 'post',
                    },
                },
                txtPasswordConfirm: {
                    equalTo: '#txtPassword',
                },
            },
            messages: {
                txtEmail: {
                    required: strLabelGiveEmail,
                    customemail: strLabelEmailInvalid,
                },
                txtPasswordConfirm: {
                    equalTo: strLabelPasswordsDontMatch,
                },
            },
            errorClass: 'd-flex invalid-feedback',
            errorElementClass: 'is-invalid',
            highlight: function (element, errorClass, validClass) {
                $(element).addClass(this.settings.errorElementClass).removeClass(errorClass);
            },
            unhighlight: function (element, errorClass, validClass) {
                $(element).removeClass(this.settings.errorElementClass).removeClass(errorClass);
            },
        });

    return validator;
}


// Edit address data
function FormValidateAddressData(objForm) {
    var validator = $(objForm).validate(
        {
            rules: {
                // Check if vat number is valid
                txtVatnr: {
                    remote: {
                        url: strAjaxPath + 'validate/register-vat.php',
                        type: 'post',
                        data: {
                            txtVatpr: function () {
                                return $('#txtVatpr').val();
                            },
                        },
                    },
                },
            },
            errorClass: 'd-flex invalid-feedback',
            errorElementClass: 'is-invalid',
            highlight: function (element, errorClass, validClass) {
                $(element).addClass(this.settings.errorElementClass).removeClass(errorClass);
            },
            unhighlight: function (element, errorClass, validClass) {
                $(element).removeClass(this.settings.errorElementClass).removeClass(errorClass);
            },
            // Error zipcode has to be shown after field 'city'
            errorPlacement: function (error, element) {
                if (element.attr('name') == 'txtZipcode') {
                    error.insertAfter('#txtCity');
                } else if (element.attr('name') == 'txtStreet') {
                    error.insertAfter('#txtAddressNumber');
                } else {
                    error.insertAfter(element);
                }
            },
            messages: {
                txtFirstname: {
                    required: strLabelGiveFirstname,
                },
                txtLastname: {
                    required: strLabelGiveLastname,
                },
                txtStreet: {
                    required: strLabelGiveStreet,
                },
                txtAddressNumber: {
                    required: strLabelGiveAddressNumber,
                },
                txtZipcode: {
                    required: strLabelGiveZipcode,
                },
                txtCity: {
                    required: strLabelGiveCity,
                },
                txtPhone: {
                    required: strLabelGivePhoneMobile,
                },
            },
        });

    return validator;
}


// Add address to address book
function FormValidateAddAddress(objForm) {
    var validator = $(objForm).validate(
        {
            messages: {
                txtFirstname: {
                    required: strLabelGiveFirstname,
                },
                txtLastname: {
                    required: strLabelGiveLastname,
                },
                txtStreet: {
                    required: strLabelGiveStreet,
                },
                txtAddressNumber: {
                    required: strLabelGiveAddressNumber,
                },
                txtZipcode: {
                    required: strLabelGiveZipcode,
                },
                txtCity: {
                    required: strLabelGiveCity,
                },
            },
            errorClass: 'd-flex invalid-feedback',
            errorElementClass: 'is-invalid',
            highlight: function (element, errorClass, validClass) {
                $(element).addClass(this.settings.errorElementClass).removeClass(errorClass);
            },
            unhighlight: function (element, errorClass, validClass) {
                $(element).removeClass(this.settings.errorElementClass).removeClass(errorClass);
            },
        });

    return validator;
}

// Edit address from address book
function FormValidateUpdateAddress(objForm) {
    var validator = $(objForm).validate(
        {
            messages: {
                txtFirstname: {
                    required: strLabelGiveFirstname,
                },
                txtLastname: {
                    required: strLabelGiveLastname,
                },
                txtStreet: {
                    required: strLabelGiveStreet,
                },
                txtAddressNumber: {
                    required: strLabelGiveAddressNumber,
                },
                txtZipcode: {
                    required: strLabelGiveZipcode,
                },
                txtCity: {
                    required: strLabelGiveCity,
                },
            },
            errorClass: 'd-flex invalid-feedback',
            errorElementClass: 'is-invalid',
            highlight: function (element, errorClass, validClass) {
                $(element).addClass(this.settings.errorElementClass).removeClass(errorClass);
            },
            unhighlight: function (element, errorClass, validClass) {
                $(element).removeClass(this.settings.errorElementClass).removeClass(errorClass);
            },
        });

    return validator;
}

// Cart logged in
function FormValidateCartLoggedIn(objForm) {

    var validator = $(objForm).validate(
        {
            // Ignore has to be here, because then it shows also error messages if the form isn't visible
            ignore: [],
            // Error zipcode must be shown after field 'city'
            errorPlacement: function (error, element) {
                if (element.attr('name') == 'txtZipcode') {
                    error.insertAfter('#txtCity');
                } else if (element.attr('name') == 'txtStreet') {
                    error.insertAfter('#txtAddressNumber');
                } else {
                    error.insertAfter(element);
                }
            },
            invalidHandler: function (event, validator) {
                // In step billing data in cart => show input fields (if needed)
                $('a#billing-modify').click();
            },
            errorClass: 'd-flex invalid-feedback',
            errorElementClass: 'is-invalid',
            highlight: function (element, errorClass, validClass) {
                $(element).addClass(this.settings.errorElementClass).removeClass(errorClass);
            },
            unhighlight: function (element, errorClass, validClass) {
                $(element).removeClass(this.settings.errorElementClass).removeClass(errorClass);
            },
            messages: {
                txtFirstname: {
                    required: strLabelGiveFirstname,
                },
                // txtLastname: {
                //     required: strLabelGiveLastname,
                // },
                txtStreet: {
                    required: strLabelGiveStreet,
                },
                txtAddressNumber: {
                    required: strLabelGiveAddressNumber,
                },
                txtZipcode: {
                    required: strLabelGiveZipcode,
                },
                txtCity: {
                    required: strLabelGiveCity,
                },
                // txtPhone: {
                //     required: strLabelGivePhoneMobile,
                // },
                txtEmail: {
                    required: strLabelGiveEmail,
                    customemail: strLabelEmailInvalid,
                },
            },
        });

    return validator;
}

// Cart delivery - transport input (alternative delivery address)
function FormValidateCartTransportInput(objForm) {
    var validator = $(objForm).validate(
        {
            // Error zipcode must be shown after field 'city'
            errorPlacement: function (error, element) {
                if (element.attr('name') == 'txtTransportZipcode') {
                    error.insertAfter('#txtTransportCity');
                } else {
                    error.insertAfter(element);
                }
            },
            errorClass: 'd-flex invalid-feedback',
            errorElementClass: 'is-invalid',
            highlight: function (element, errorClass, validClass) {
                $(element).addClass(this.settings.errorElementClass).removeClass(errorClass);
            },
            unhighlight: function (element, errorClass, validClass) {
                $(element).removeClass(this.settings.errorElementClass).removeClass(errorClass);
            },
            messages: {
                txtTransportFirstname: {
                    required: strLabelGiveFirstname,
                },
                txtTransportLastname: {
                    required: strLabelGiveLastname,
                },
                txtTransportAddress: {
                    required: strLabelGiveAddress,
                },
                txtTransportZipcode: {
                    required: strLabelGiveZipcode,
                },
                txtTransportCity: {
                    required: strLabelGiveCity,
                },
            },
        });

    return validator;
}

// Gift card
function FormValidateGiftCard(objForm) {
    var validator = $(objForm).validate(
        {
            messages: {
                txtMessage: {
                    required: strLabelGiveMessage,
                },
                txtName: {
                    required: strLabelGiveName,
                },
                txtEmail: {
                    required: strLabelGiveEmail,
                    customemail: strLabelEmailInvalid,
                },
                txtReceiverName: {
                    required: strLabelGiveNameReceiver,
                },
                txtReceiverEmail: {
                    required: strLabelGiveEmailReceiver,
                    customemail: strLabelEmailReceiverInvalid,
                },
            },
            errorClass: 'd-flex invalid-feedback',
            errorElementClass: 'is-invalid',
            highlight: function (element, errorClass, validClass) {
                $(element).addClass(this.settings.errorElementClass).removeClass(errorClass);
            },
            unhighlight: function (element, errorClass, validClass) {
                $(element).removeClass(this.settings.errorElementClass).removeClass(errorClass);
            },
            submitHandler: function () {

                // Make a string from all input fields and make them begin with str, int
                var strGetParams = objForm.serialize();
                var strParams = strGetParams.replace(/&txt/g, '&str');
                strParams = strParams.replace('&hdnFormType', '&strFormType');
                strParams = strParams.replace(/&hdn/g, '&int');
                strParams = strParams.replace(/&opt/g, '&str');

                // Add giftcard to cart after the form is validated
                AddProductToCart(objForm, $('#btnAddToCart'), false, false, strParams);
            },
        });

    return validator;
}

// Add a new wishlist
function FormValidateAddWishlist(objForm, strArticles = '') {
    var validator = $(objForm).validate({
        strArticles: strArticles,
        ignore: [],
        errorPlacement: function (error, element) {
            if (element.attr('name') == 'txtTitle') {
                error.insertAfter('#frmWishlist');
            } else if (element.is('input[type="hidden"]')) {
                error.appendTo(element.closest('li'));
            } else {
                error.insertAfter(element);
            }
        },
        messages: {
            txtTitle: {
                required: strLabelGiveTitle,
            },
        },
        errorClass: 'd-flex invalid-feedback',
        errorElementClass: 'is-invalid',
        highlight: function (element, errorClass, validClass) {
            $(element).addClass(this.settings.errorElementClass).removeClass(errorClass);
        },
        unhighlight: function (element, errorClass, validClass) {
            $(element).removeClass(this.settings.errorElementClass).removeClass(errorClass);
        },
        submitHandler: function (element, strArticles) {
            var strParam = '';
            if (this.settings.strArticles !== '') {
                strParam = '&strWishlistArticles=' + this.settings.strArticles;
            }
            $.post(strAjaxPath + 'wishlist/wishlist-add-list.php', objForm.serialize() + strParam, function (data) {
                if (data == 'OK') {
                    window.location.reload();
                }
            });
        },
    });
    return validator;
}


window.FormValidateAddWishlist = FormValidateAddWishlist;
window.FormValidateGiftCard = FormValidateGiftCard;
window.FormValidateCartTransportInput = FormValidateCartTransportInput;
window.FormValidateCartLoggedIn = FormValidateCartLoggedIn;
window.FormValidateUpdateAddress = FormValidateUpdateAddress;
window.FormValidateAddAddress = FormValidateAddAddress;
window.FormValidateAddressData = FormValidateAddressData;
window.FormValidateAddressAccount = FormValidateAddressAccount;
window.FormValidateRegister = FormValidateRegister;
window.FormValidateForgotPasswordReset = FormValidateForgotPasswordReset;
window.FormValidateForgotPassword = FormValidateForgotPassword;
window.FormValidateLogin = FormValidateLogin;
window.FormValidateUnsubscribe = FormValidateUnsubscribe;
